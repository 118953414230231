<template>
  <ListView></ListView>
</template>

<script>

import './assets/css/jquery.mobile.min.css'
import './assets/css/common.css'

import ListView from './components/ListView.vue'

export default {
  name: 'App',
  components: {
    ListView
  }
}
</script>
