<template>
    <div id="player"></div>
    <div class="clear" style="height:2rem"></div>
    <div id="itemList">
        <div class="more">
            <i :style="{ backgroundImage: 'url(' + data.img + ')' }"></i>
            <h5>{{ data.name }}</h5>
            <span>{{ data.type }} · {{ data.hits }} · {{ data.time }}</span>
        </div>
    </div>
</template>
<script>
import '../assets/css/play.css'
import Hls from 'hls.js/dist/hls.min.js'
import DPlayer from 'dplayer';
export default {
    props: ['data'],
    mounted() {
        new DPlayer({
            element: document.getElementById('player'),
            logo: 'assets/images/logo_play.png',
            autoplay: true,
            video: {
                url: this.data.video,
                pic: this.data.img,
                type: 'customHls',
                customType: {
                    customHls: function (video) {
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                    },
                }
            },
            apiBackend: {
                hook: {
                    beforeToggleScreen: function () {
                        const logoRight = document.getElementsByClassName('dplayer-logo-right-show')[0];
                        logoRight.style.display = 'block';
                        setTimeout(() => {
                            logoRight.style.opacity = 1;
                        }, 0);
                    },
                }
            }
        });
        document.querySelector('#player').oncontextmenu = () => {
            document.querySelector('.dplayer-menu').style.display = 'none';
            document.querySelector('.dplayer-mask').style.display = 'none';
            return false;
        };
    }
}
</script>