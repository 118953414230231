<template>
  <div class="body">
    <div class="header">
      <div class="logo" style="background-image:url(assets/images/logo.png)"></div>
      <div class="search">
        <input type="text" placeholder="输入你想看的" v-model="k" @keyup.enter="get_search(k, 1)" />
        <a id="searchDo" @click="get_search(k, 1)"></a>
      </div>
      <div class="navigate">
        <a href="javascript:location.reload()" :class="{ 'current': k === '' }">主页</a>
        <a @click="get_search('国产', 1)" :class="{ 'current': k === '国产' }">国产</a>
        <a @click="get_search('萝莉', 1)" :class="{ 'current': k === '萝莉' }">萝莉</a>
        <a @click="get_search('00后', 1)" :class="{ 'current': k === '00后' }">00后</a>
        <a @click="get_search('外围', 1)" :class="{ 'current': k === '外围' }">外围</a>
      </div>
    </div>

    <lay-loading v-if="isLoading"></lay-loading>

    <div class="list" v-if="!isLoading && type == 1">
      <div id="searchInfo">
        <div class="no-data" style="margin-top:1rem;background:none" v-if="videoList == [] && !k">请输入关键词进行搜索</div>
        <div class="items" v-if="videoList != [] && k">
          <a v-for="res in videoList" :key="res.id" @click="get_video(res.id)">
            <i :style="{ backgroundImage: 'url(' + res.img + ')' }"><b>{{ res.hits }} · {{ res.time }}</b></i><span>{{
              res.name }}</span>
          </a>
          <span class="clear"></span>
        </div>
      </div>
      <div class="more" v-if="!isLoading && isPage && k">
        <a class="prev" @click="get_search(k, page - 1)" v-if="prevPage"><img src="../assets/images/more.png">上一页</a>
        <a class="next" @click="get_search(k, page + 1)" v-if="nextPage">下一页<img src="../assets/images/more.png"></a>
      </div>
    </div>

    <VideoView :data="videoData" v-if="!isLoading && type == 2"></VideoView>

    <lay-backtop></lay-backtop>

    <div class="clear" style="height:2rem"></div>

    <div class="copyright">
      <p>本站内容均来自于互联网资源实时采集</p>
      <p>目前正在测试中，反馈邮箱：langshen233@gmail.com</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { layer } from "@layui/layui-vue"
import VideoView from './VideoView.vue';
export default {
  components: {
    VideoView
  },
  data() {
    return {
      k: '', // 关键词
      page: 1, // 当前分页
      videoList: [], // 视频列表
      videoData: [], // 视频数据
      isLoading: false, // 是否加载
      isPage: false, // 是否开启分页
      prevPage: false, // 是否开启上一页
      nextPage: false, // 是否开启下一页
      type: 1, // 1:列表 2:播放
    }
  },
  mounted() {
  },
  methods: {
    async get_video(id) {
      this.isLoading = true
      try {
        let ret = await axios.get('https://api.let1.eu.org/sss/get?id=' + id)
        if (!ret.data.data) {
          layer.msg('获取视频资源失败，请稍后重试', { icon: 2 })
          return false
        }
        this.type = 2
        this.isLoading = false
        this.videoData = ret.data.data
      } catch (error) {
        this.isLoading = false
        layer.msg('获取视频资源失败，请稍后重试', { icon: 2 })
      }
    },
    get_search(k, page) {
      this.k = k
      this.page = page < 1 ? 1 : page
      this.get_list()
    },
    async get_list() {
      if (!this.k) {
        layer.msg('请输入关键词', { icon: 3 })
        return false;
      }
      this.isLoading = true
      try {
        this.type = 1
        let ret = await axios.get('https://api.let1.eu.org/sss/search?k=' + this.k + '&page=' + this.page)
        this.isLoading = false
        if (!ret.data.data) {
          layer.msg('没有更多数据了', { icon: 2 })
          return false;
        }
        //this.page++
        this.videoList = ret.data.data
        if (ret.data.pages != 1) {
          this.isPage = true
          if (this.page <= 1) {
            this.prevPage = false
          } else {
            this.prevPage = true
          }
          if (this.page >= ret.data.pages) {
            this.nextPage = false
          } else {
            this.nextPage = true
          }
        } else {
          this.isPage = false
        }
      } catch (error) {
        this.isLoading = false
        layer.msg('获取资源失败 请稍后重试', { icon: 2 })

      }

    }
  }
}
</script>